<template>
	<v-app>
		<v-app-bar app color="gray"  height="115" hide-on-scroll prominent>
			<v-row>
				<v-spacer></v-spacer>
				<v-col class="col-4 col-sm-4 col-md-2 col-lg-2">
					<v-img :alt="logo.name" class="shrink mr-2" contain :src="logo.img" transition="scale-transition" width="132"></v-img>
				</v-col>
				<v-col class="col-6 col-sm-6 col-md-10 col-lg-7 ">
					<v-row>
						<v-spacer></v-spacer>
						<v-col class="col-12 col-md-3 pa-0 text-center"><v-btn class="ma-2" dark color="primary2" width="150">Пожертвовать</v-btn></v-col>
						<v-col class="col-12 col-md-3 pa-0 text-center"><v-btn class="ma-2" dark color="primary" width="150">Спонсоры</v-btn></v-col>
						<v-col class="col-1 text-center d-none d-md-block" v-for="(b,i) in buttons" :key="i">
							<v-btn :href="b.link"  class="dinamic_link" fab small text><center><v-img :src="b.img" height="24" width="24"></v-img></center></v-btn>
						</v-col>
					</v-row>

					<v-row  color="primary" class="d-none d-md-block">
						<!--<v-col v-for="(m, i) in menu" :key="i"><a href="" color="primary">{{ m.name }}</a></v-col>-->
						<v-tabs v-model="tabs" centered optional slider-color="primary" background-color="gray">
							<v-tab v-for="(m,i) in menu" :key="i">{{ m.name }}</v-tab>
						</v-tabs>
					</v-row>
				</v-col>
				<v-col class="col-2 col-sm-1 d-md-none"><v-app-bar-nav-icon></v-app-bar-nav-icon></v-col>
				<v-spacer></v-spacer>
			</v-row>

		</v-app-bar>
		<v-expand-transition >
			<v-sheet color="gray2" width="100%" style="position: fixed; top: 115px; z-index: 5;" v-if="tabs">
				<v-row class="px-10 py-3">
					<v-col class="col-6 col-lg-4" v-for="(s,i) in menu[tabs].submenu" :key="i"><v-btn dark text>{{ s.name }}</v-btn></v-col>
				</v-row>
			</v-sheet>
		</v-expand-transition>

		<!-- Navigation menu -->
		<v-navigation-drawer v-model="drawer" absolute temporary>
			<v-list nav dense>
				<v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
					<v-list-item> 
						<v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon>
						<v-list-item-title>Home</v-list-item-title>
					</v-list-item>

					<v-list-item>
						<v-list-item-icon>
							<v-icon>mdi-account</v-icon>
						</v-list-item-icon>
						<v-list-item-title>Account</v-list-item-title>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>

		<!-- Main component -->
		<v-main>
			<v-container>
				<router-view></router-view>
			</v-container>
		</v-main>

		<!-- footer -->
		<v-footer padless >
			<v-row class="ma-auto">
				<v-col class="col-12 col-sm-6 col-md-4">
					<ul>
						<li v-for="(m,i) in menu_list" :key="i" >
							<a href="" class="menu_list">{{ m.name }}</a>
						</li>
					</ul>
				</v-col>
				<v-col class="col-12 col-sm-6 col-md-4 d-none d-md-block">
					<v-img :alt="logo.name" class="ma-auto" contain :src="logo.img" transition="scale-transition" width="132"></v-img>
				</v-col>
				<v-col class="col-12 col-sm-6 col-md-4">
					<v-row  justify="center">
						<v-col class="col-12 text-center"><h2>+7(499) 713-0777</h2></v-col>
						<v-col class="col-12">
							<v-row justify="center">
								<v-col class="col-2 text-center" v-for="(b,i) in buttons" :key="i">
									<v-btn :href="b.link"  class="dinamic_link" fab small text><center><v-img :src="b.img" height="24" width="24"></v-img></center></v-btn>
								</v-col>
							</v-row>
						</v-col>
						<v-col class="col-12">
							<v-row>
								<v-col class="col-12 py-0"><v-btn class="ma-2" block dark color="primary2">Пожертвовать</v-btn></v-col>
								<v-col class="col-12 py-0"><v-btn class="ma-2" block dark color="primary">Оставить заявку</v-btn></v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-col>
				<v-col class="col-12 text-center">{{ new Date().getFullYear() }} — <strong>SpayCenter</strong></v-col>
			</v-row>
		</v-footer>

	</v-app>
</template>

<script>

export default {
	name: 'App',

	data: () => ({
		tabs:'',
		logo: {name:'SpayCenter Logo', img:'/img/logo.png'},
		buttons:[
			{link:'https://t.me/kondor_vet', img:'img/icon_telegram.png'},
			{link:'https://vk.com/kondorvet', img:'img/icon_vk.png'},
			{link:'mailto:info@kondorvet.ru?subject=SpayCenter', img:'img/icon_email.png'}
		],
		menu:[
			{name:'Потеряшки', link:''},
			{name:'О нас', link:'', submenu:[
				{name:'Наш взгляд'},
				{name:'Наша история'},
				{name:'Годовой отчет'},
				{name:'Познакомиться с попечителями'},
				{name:'Структура, руководство, управление'},
				{name:'Корпоративные партнеры'},
			]},
			{name:'Что мы делаем', link:'', submenu:[
				{name:'Образование'},
				{name:'Агитация'},
				{name:'Кастрация'},
				{name:'Фин.помощь'},
			]},
			{name:'Помощь и статьи', link:'', submenu:[
				{name:'О кастрации'},
				{name:'О вакцинации'},
				{name:'О беременности'},
				{name:'О кормлении'},
				{name:'Потерял кошку, что делать'},
				{name:'Нашел кошку, что делать'},
			]},
			{name:'Найти центр', link:''},
		],
	}),
	computed:{
		menu_list(){
			// Возвращаем полный списсок меню
			var res = []
			for (var i in this.menu){
				res.push(this.menu[i])
				if (this.menu[i].submenu){
					console.log("FIND SUBMENU")
					for (var j in this.menu[i].submenu){
						res.push(this.menu[i].submenu[j])
					}
				}
			}
			return res
		}
	},
};
</script>





<style>

.secondary_o {
	background-color:rgb(255, 173, 255, 0.6)
}

.menu_list {
	
	text-decoration: auto;
}
.menu_list:hover {
	font-weight: 700;
}

</style>