import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

// Установка цветовой палитры
import colors from 'vuetify/lib/util/colors'
const color_light = {
	primary: '#B453A9',
	primary2:'#1BC540',
	gray:'#F3F3F3',
	gray2:'#8F8F8F',

	//secondary: colors.grey.darken1,
	//secondary: '#B6E7FF',
	//secondary: '#C0F1FF',
	secondary: '#FFADFF',
	//secondary_t: rgb(255, 173, 255, 0.6),
	accent: colors.shades.black,
	error: colors.red.accent3,
}
const color_dark = {
	primary: colors.red,
	secondary: colors.grey.darken1,
	accent: colors.shades.black,
	error: colors.red.accent3,
}
vuetify.framework.theme.themes.light = color_light
vuetify.framework.theme.themes.dark = color_dark
// --------------------------------------------


new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
