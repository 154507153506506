<template>
	<div class="home" >
		<v-row>
			<v-col>
				<v-img max-height="516" src="img/cat04.jpg" contain>
					<template v-slot:default>
						<v-row class="fill-height ma-0" align="center" justify="center" >

							<v-col class="col-12 col-sm-8 col-md-6 py-10 secondary_o" >
								<h1>“Мы здесь, для того, чтобы помочь людям увидеть мир глазами животных”</h1>
							</v-col>
							<v-col class="col-0 col-sm-4 col-md-6"></v-col>
							<v-col class="col-12 text-center">
								<v-btn class="mx-2 primary2"  width="300">Пожертвовать</v-btn>
								<v-btn class="mx-2 primary" width="300">Запись на кастрацию</v-btn>
							</v-col>
						</v-row>
					</template>
				</v-img>
			</v-col>
		</v-row>
		<!-- ------------------------------- -->	
		<v-row class="secondary_o">
			<v-col class="col-12 col-sm-4 col-md-3" v-for="(b, i) in info_buttons" :key="i">
				<v-card height="200" width="200" color="gray" class="ma-auto py-3">
					<v-img :src="b.img" :alt="b.name" height="124px" contain></v-img>
					<p class="text-center text-uppercase font-weight-bold my-5">{{ b.name }}</p>
				</v-card>
			</v-col>
		</v-row>
		<!-- ------------------------------- -->	
		<v-row>
			<v-col>
				<v-row>
					<v-col><h2>Наши партреры</h2></v-col>
				</v-row>
				<v-row>
					<v-col class="col-12 col-sm-4 col-md-2" v-for="(p,i) in partners" :key="i">
						<v-img :src="p.img" max-width="147" contain></v-img>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<!-- ------------------------------- -->	

	</div>
</template>

<script>

export default {
	name: 'Home',
	components: {
	},
	data: () => ({
		info_buttons: [
			{name:'Обучение', img:'/img/library.png'},
			{name:'Волонтерам', img:'/img/volunteer.png'},
			{name:'Кастрация', img:'/img/neutering.png'},
			{name:'Контакты', img:'/img/contacts.png'},
		],
		partners:[
			{name:'Ветеринарный дайджест (veterinary digest)', img:'/img/vd_logo.png'},
			{name:'Ветеринарная клиника "Кондор"', img:'/img/kondor04.png'},
		],
	})
}

</script>